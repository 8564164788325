import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				SparkleShine Cleaning
			</title>
			<meta name={"description"} content={"Робимо кожен простір бездоганним"} />
			<meta property={"og:title"} content={"SparkleShine Cleaning"} />
			<meta property={"og:description"} content={"Робимо кожен простір бездоганним"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-2.jpg?v=2024-06-14T12:44:47.442Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-2.jpg?v=2024-06-14T12:44:47.442Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-2.jpg?v=2024-06-14T12:44:47.442Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-2.jpg?v=2024-06-14T12:44:47.442Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-2.jpg?v=2024-06-14T12:44:47.442Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-2.jpg?v=2024-06-14T12:44:47.442Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-2.jpg?v=2024-06-14T12:44:47.442Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/unique-handdrawn-floral-modern-shapes.png?v=2023-04-27T21:48:23.623Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-3.jpg?v=2024-06-14T12:44:47.450Z"
						display="block"
						max-width="100%"
						width="350px"
						srcSet="https://smartuploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-3.jpg?v=2024-06-14T12%3A44%3A47.450Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-3.jpg?v=2024-06-14T12%3A44%3A47.450Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-3.jpg?v=2024-06-14T12%3A44%3A47.450Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-3.jpg?v=2024-06-14T12%3A44%3A47.450Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-3.jpg?v=2024-06-14T12%3A44%3A47.450Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-3.jpg?v=2024-06-14T12%3A44%3A47.450Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07b38d4a0c0020306e49/images/2-3.jpg?v=2024-06-14T12%3A44%3A47.450Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Послуги
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						Наш комплексний набір послуг із прибирання створений для того, щоб забезпечити задоволення ваших потреб у прибиранні з найвищим професіоналізмом і увагою до деталей, незалежно від того, власник ви будинку чи бізнесмен.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Рішення для детального очищення
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					У SparkleShine Cleaning ми не просто прибираємо – ми перетворюємо простір. Спектр наших послуг охоплює від щоденного обслуговування до спеціалізованих прибирань, які виконує наша команда відданих своїй справі професіоналів з прибирання.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					У SparkleShine Cleaning ми не просто прибираємо – ми перетворюємо простір. Спектр наших послуг охоплює від щоденного обслуговування до спеціалізованих прибирань, які виконує наша команда відданих своїй справі професіоналів з прибирання.
				</Text>
				<Text
					as="h1"
					margin="3rem 0px 0px 0px"
					font="--headline3"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Різноманітні пропозиції щодо прибирання
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Послуги з прибирання будинків
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Регулярне технічне обслуговування:** Регулярні візити для виконання таких завдань, як видалення пилу, пилососіння та прибирання.
**Глибоке прибирання:** Ретельне прибирання важкодоступних і запущених місць, ідеальне для сезонного прибирання або підготовки до особливої ​​події.
**Прибирання при заїзді/виїзді: ** Цільові послуги для забезпечення незайманості майна для нових орендарів або після виїзду.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Комерційні програми очищення
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Корпоративне прибирання офісу:** Настроювані графіки прибирання для підтримки бездоганного та професійного бізнес-середовища.
**Прибирання роздрібної торгівлі та гостинності: ** Індивідуальні рішення для магазинів, готелів і ресторанів, які зберігають простір затишним і гігієнічним.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--light" margin="0px 0px 6px 0px">
							Спеціальні послуги з прибирання
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Екологічне прибирання:** Використання екологічно чистих засобів для чищення, які є ефективними, але дбайливими для навколишнього середовища.
**Післябудівельне прибирання:** детальне очищення від пилу та сміття після ремонту або будівництва, щоб підготувати нове приміщення до використання.
**Прибирання подій:** Послуги з прибирання до та після подій для корпоративних заходів, весіль та інших великих заходів.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Дослідіть весь спектр наших можливостей
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					У цьому огляді лише наведено поверхню того, що може надати SparkleShine Cleaning. Кожна наша послуга підтримується прагненням до екологічно чистих практик і задоволеності клієнтів. Ми розуміємо, що кожен простір унікальний, і ми прагнемо налаштувати наші послуги відповідно до ваших конкретних потреб. Зверніться до нас, щоб дізнатися, як ми можемо допомогти вам підтримувати незаймане довкілля.
				</Text>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});